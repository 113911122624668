import React from "react"

import Button from "@material-ui/core/Button"
import Container from "@ecom/ui/components/Container"

import scrollToPersonalForm from "../../../helpers/scrollToPersonalForm"

import * as styles from "./compare.module.scss"

import icon_card from "./img/icon_card.svg"
import icon_message from "./img/icon_message.svg"
import icon_percent from "./img/icon_percent.svg"
import icon_wallet from "./img/icon_wallet.svg"

const dataIcons = [icon_card, icon_percent, icon_wallet, icon_message]
const dataHead = ["Обслуживание", "Ставка", "Снятие наличных", "СМС информирование"]
const dataHalva = ["0 ₽", "0%", "290 ₽ + 2,9% <br> или 0% с подпиской", "0 ₽"]
const dataCredit = ["до 1490 ₽ / год", "до 49,9%", "до 5,9%", "до 59 ₽ / мес."]

type ConditionsProps = {
  orderNum?: string
}

export default function Conditions({ orderNum }: ConditionsProps) {
  const items = (mobile: boolean) =>
    dataHead.map((item, i) => (
      <tr key={i}>
        {!mobile && (
          <td>
            <h4 className={styles.title}>
              <img alt="icon" className={styles.icons} src={dataIcons[i]} />
              {item}
            </h4>
          </td>
        )}
        <td>
          {mobile && <h4 className={styles.title}>{item}</h4>}
          <p className={styles.desc} dangerouslySetInnerHTML={{ __html: dataHalva[i] }} />
          {i === dataHead.length - 1 ? (
            <Button color="secondary" className={styles.btn} onClick={scrollToPersonalForm}>
              Заказать карту
            </Button>
          ) : null}
        </td>
        <td>
          {mobile && <h4 className={styles.title}>{item}</h4>}
          <p className={styles.desc} dangerouslySetInnerHTML={{ __html: dataCredit[i] }} />
        </td>
      </tr>
    ))

  return (
    <section data-exclude={orderNum}>
      <h2 className={styles.head}>Преимущества халвы</h2>
      <Container className={styles.container}>
        <table className={styles.wrapMobile}>
          <tbody>{items(true)}</tbody>
        </table>
        <table className={styles.wrapUpMobile}>
          <tbody>{items(false)}</tbody>
        </table>
      </Container>
    </section>
  )
}
