import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import Banner from "../components/BestHalva/Banner"
import Advantages from "../components/Advantages"
import Compare from "../components/BestHalva/Compare"
import { PageData } from "../interfaces/pageProps"
import { FormPKW } from "../components/FormPKW"

import { getPageData } from "../helpers/getPageData"

export default function BestHalvaPage({ data }: PageProps<PageData>) {
  const { ligal, phones, noIndex } = getPageData(data)

  return (
    <Layout ligal={ligal} phones={phones} noIndex={noIndex}>
      <Banner orderNum="1" />
      <Advantages orderNum="2" />
      <Compare orderNum="3" />
      <FormPKW
        longForm
        dataLayerName="shortPersonalForm"
        title="Оформить заявку"
        longTitle
        additionalEventInDataLayer
        formBtnText="Оформить"
        orderNum="4"
        subTitle="Заберите его в ближайшем офисе Совкомбанка"
        emptyForm={false}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/besthalva/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
