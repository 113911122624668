import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"

import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const buttonText = "Заказать карту"

type BackToSchoolProps = {
  orderNum?: string
}

export default function BackToSchool({ orderNum }: BackToSchoolProps) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Container className={styles.container}>
        <Img alt="bg image" className={styles.img} />
        <h1 className={styles.head}>
          ХАЛВА ЛУЧШЕ
          <br /> КРЕДИТКИ
        </h1>
        <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
          {buttonText}
        </Button>
      </Container>
    </section>
  )
}
